import React, { SVGProps } from 'react';

const PulseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg height="20" width="20" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.4922 6.24841C17.1543 7.38781 17.5029 8.68218 17.5026 9.99998"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M6.24895 16.4927C5.11167 15.8339 4.16663 14.8888 3.50781 13.7516"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M13.7516 3.50728C12.6122 2.8452 11.3178 2.49658 10 2.49686"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M10 17.5031C12.6779 17.498 15.1509 16.069 16.4927 13.7516"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M6.24766 3.50729C3.93017 4.84905 2.5012 7.32212 2.49609 10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <circle cx="10.0004" cy="9.99998" r="4.1684" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  );
};

export default PulseIcon;
