import { createGlobalStyle, keyframes } from 'styled-components';

import { ThemeProp } from './Themes';

export const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  60%: {
    opacity: 0.75;
  },
  100% {
    opacity: 1;
  }
`;

export const GlobalStyles = createGlobalStyle<ThemeProp>`
  body {
    background: ${({ theme }: ThemeProp): string => theme.colors.neutrals.white};
    color: ${({ theme }: ThemeProp): string => theme.colors.neutrals.black};
    margin: 0; 
    padding: 0
  }
  
  h4 {
    font-weight: 600;
    margin: 0;
    line-height: 24px;
    font-size: 16px;
  }
  
  .rjsf-form {
    width: 100%;
  }
  
  input:-webkit-autofill, input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }

  .nextui-popover-content-container {
    border-radius: ${({ theme }: ThemeProp): string => theme.borderRadius};
    &.nextui-popover-content-enter {
      animation-duration: 0ms;
    }
  }

  .nextui-popover-content {
    max-height: inherit;
    .nextui-table-container {
      box-shadow: none;
    }
    animation-name: ${fadeAnimation};
    animation-timing-function: ease-out;
    animation-duration: 250ms;
  }

  .tooltip-wrapper, .tooltip-wrapper-small, .tooltip-pixel-disabled, .tooltip-filter-wrapper, .tooltip-people-table, .tooltip-columns-list, .tooltip-add-people{
    padding: 0.5rem;
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
    max-width: 15rem;
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c100};
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
    font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
    z-index: 100001;

    .nextui-tooltip-arrow{
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
    }

  }

  .tooltip-integrations-wrapper{
    padding: 0.75rem;
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
    max-width: 17.5rem;
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
    font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05), 0px 0px 5px 0px rgba(0, 0, 0, 0.03);
    z-index: 8000;

    .nextui-tooltip-arrow{
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
      z-index: 8001;
      bottom: -0.5rem;
      border-bottom: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
      border-right: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
    }

  }

  .tooltip-wrapper-small{
    max-width: 11.4rem;
  }

  .tooltip-pixel-disabled {
    @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
      max-width: 12rem;
    }
  }

  .tooltip-filter-wrapper {
    display: flex;
    min-width: 9rem;
    justify-content: center;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;

    @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
      max-width: 13.5rem;
    }
  }
  
  .tooltip-people-table {
    background: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    display: flex;
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c100};


    .nextui-tooltip-arrow{
      background: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
    }
  }

  .tooltip-columns-list{
    max-width: 27rem;

    @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
      max-width: 19.5rem;
    }
  }

  .tooltip-add-people{
    max-width: 16rem;
    min-width: 12rem;
    flex: 1;
  }

  .nextui-tooltip-button {
    width: unset;
  }

  // To style the popover arrow for the Journeys
  .__floater.__floater__open[x-placement="right-start"], .__floater.__floater__open[x-placement="right"], .__floater.__floater__open[x-placement="right-end"] {
    padding: 0 0 0 10px !important;
  }

  .__floater.__floater__open[x-placement="bottom"], .__floater.__floater__open[x-placement="bottom-left"], .__floater.__floater__open[x-placement="bottom-right"] {
    padding: 10px 0 0 !important;
  }

  .__floater.__floater__open[x-placement="top"], .__floater.__floater__open[x-placement="top-right"], .__floater.__floater__open[x-placement="top-left"] {
    padding: 0 0 10px 0 !important;
  }

  .__floater.__floater__open[x-placement="left-start"], .__floater.__floater__open[x-placement="left"], .__floater.__floater__open[x-placement="left-end"] {
    padding: 0 10px 0 0 !important;
  }

  .react-multi-email:focus {
    box-shadow: 0 2px 8px 2px rgb(104 112 118 / 0.07), 0 2px 4px -1px rgb(104 112 118 / 0.04);
  }

  .react-multi-email [data-tag="true"] {
    background-color: ${({ theme }: ThemeProp): string => theme.colors.primaryLight} !important;
    color: ${({ theme }: ThemeProp): string => theme.colors.primary.c500} !important;
    font-size: ${({ theme }: ThemeProp): string => theme.fontSize.s} !important;
    line-height: ${({ theme }: ThemeProp): string => theme.lineHeight.s} !important;
    font-weight: ${({ theme }: ThemeProp): number => theme.fontWeight.regular} !important;
    padding: 0.1rem 0.5rem !important;
    margin: 0.125rem;

    ::placeholder {
      color: ${({ theme }: ThemeProp): string => theme.colors.primary.c500} !important;
    }
  }

  .react-multi-email span[data-placeholder] {
    color: ${({ theme }: ThemeProp): string => theme.colors.neutrals.c400} !important;
    font-size: ${({ theme }: ThemeProp): string => theme.fontSize.m} !important;
    padding-left: 0.125rem !important;

  }

  .sidePanelOverlay {
    z-index: 9999;
  }

  .journey-panel-container{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06) !important;

  }
`;

export default GlobalStyles;
