import {
  ApolloClient,
  FetchResult,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
  ServerError,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';

import generatedFragmentMatcher from './generated/fragment-matcher';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

let authToken = undefined;

const link = split(() => !!authToken, httpLink);

type handleErrorType = (message: string, code?: string) => any;

const createAuthLink = () =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: authToken,
    },
  }));

const refreshToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const currentSession = user.signInUserSession;
  return new Promise((resolve, reject) => {
    user.refreshSession(currentSession.refreshToken, (error, session) => {
      if (error) {
        reject(error);
      }

      authToken = session.getIdToken().getJwtToken();
      resolve(undefined);
    });
  });
};

const createErrorLink = (handleError: handleErrorType) => {
  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    graphQLErrors?.forEach(({ message, extensions }) => {
      handleError(message, extensions.code as string);
    });

    if ((networkError as ServerError)?.statusCode === 401) {
      const observable = new Observable<FetchResult<Record<string, any>>>((observer) => {
        void (async () => {
          await refreshToken();

          // Retry the failed request
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        })();
      });

      return observable;
    }

    return null;
  });
};

export const createClient = (token: string | null, onError: handleErrorType) => {
  authToken = token;
  const errorLink = createErrorLink(onError);
  const links = [errorLink];
  if (authToken) {
    links.push(createAuthLink());
  }
  links.push(link);

  return new ApolloClient({
    link: from(links),
    cache: new InMemoryCache({
      possibleTypes: generatedFragmentMatcher.possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            pulseNodes: {
              keyArgs: ['parentPaths', 'searchQuery'],
              merge(existing = { nodes: [] }, incoming, { args }) {
                if (args?.after) {
                  return {
                    ...incoming,
                    nodes: [...existing.nodes, ...incoming.nodes],
                  };
                }

                return incoming;
              },
            },
          },
        },
        Audience: {
          fields: {
            pixelSettings: {
              merge: true,
            },
          },
        },
      },
    }),
    connectToDevTools: process.env.REACT_APP_APOLLO_DEVTOOLS === 'true',
  });
};
