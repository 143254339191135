export const visitIQColumns = 'visitIQ-columnsV12';
export const visitIQTaxonomyFilters = 'visitIQ-taxonomy-filtersV3';
export const visitIQGeoFilters = 'visitIQ-geo-filtersV4';
export const visitIQSettings = 'visitIQ-settings';
export const visitIQAudience = 'visitIQ-audience';
export const visitIQFilterFieldConditions = 'visitIQ-filter-field-conditionsV3';
export const visitIQGoogleAnalytics = 'visitIQ-ga';
export const visitIQTaxonomyFiltersOperators = 'visitIQ-taxonomy-filters-operatorsV1';
export const visitIQAiPrompt = 'visitIQ-ai-prompt';
export const visitIQCategories = 'visitIQ-categories';
