import { Tooltip } from '@nextui-org/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import HelpIcon from '~/assets/HelpIcon';
import Modal from '~/components/Modal';
import SupportModalContent from '~/components/SupportModalContent';
import { StyledItemContainer } from '~/components/SupportModalContent/SupportComponent.styles';
import { useAppContext } from '~/contexts/AppContext';
import { useSendSupportEmailMutation } from '~/types/api.graphql';

interface SupportComponentProps {
  collapsed?: boolean;
  trigger?: React.ReactNode;
  onVisibleChange?: (visible: boolean) => void;
  prefilledMessage?: string;
}

const SupportComponent = ({ collapsed, trigger, onVisibleChange, prefilledMessage }: SupportComponentProps): any => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>(prefilledMessage || '');
  const { showError } = useAppContext();

  const [sendSupportEmail] = useSendSupportEmailMutation();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await sendSupportEmail({ variables: { message } });
      setIsSubmitted(true);
    } catch (error) {
      showError(t('support.error'));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    onVisibleChange?.(false);
    setTimeout(() => {
      setMessage(undefined);
      setIsSubmitted(false);
    }, 200);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
    onVisibleChange?.(true);
  };

  return (
    <>
      {trigger ? (
        <div onClick={handleOpen}>{trigger}</div>
      ) : (
        <StyledItemContainer key="support-item" onClick={handleOpen}>
          {collapsed ? (
            <Tooltip content={t('support.title')} placement={'right'} hideArrow portalClassName="tooltip-wrapper">
              <HelpIcon />
            </Tooltip>
          ) : (
            <HelpIcon />
          )}
          <span>{t('support.title')}</span>
        </StyledItemContainer>
      )}
      <Modal
        title={isSubmitted ? '' : t('support.title')}
        submitButtonTitle={t('common.send')}
        open={isModalOpen}
        onClose={handleClose}
        onSubmit={() => void handleSubmit()}
        hideFooter={isSubmitted}
        scrollLocking
        loading={loading}
      >
        <SupportModalContent isSubmitted={isSubmitted} message={message} setMessage={setMessage} />
      </Modal>
    </>
  );
};

export default SupportComponent;
