import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionModal, { ActionModalComponentProps } from '../../components/Modal/ActionModal/ActionModal';
import { inMobile, useWindowDimensions } from '../../hooks/useDimensions';
import { StyledFilterButton } from '../../overwrites/components/SearchInput/FilterButton';
import { Input } from '../';
import {
  InputContainer,
  ShimmerSearchButtonWrapper,
  StyledSearchIcon,
  StyledSearchInput,
  StyledShimmerInputWrapper,
  StyledWrapper,
} from './SearchInput.styles';

interface SearchInputProps {
  loading?: boolean;
  searchText?: string;
  searchPlaceholder?: string;
  onSearchChange?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  focused?: boolean;
  fullWidth?: boolean;
  height?: string;
}

const SearchInput = ({
  loading,
  searchText,
  searchPlaceholder,
  onSearchChange,
  onFocus,
  onBlur,
  focused,
  fullWidth,
  height,
}: SearchInputProps) => {
  const { width } = useWindowDimensions();
  const [searchTextLocal, setSearchTextLocal] = useState(searchText || '');
  const [searchTextInitial, setSearchTextInitial] = useState(searchText);
  const searchModalRef = useRef(null);
  const { t } = useTranslation();

  if (searchText !== null && searchTextInitial !== searchText) {
    setSearchTextLocal(searchText);
    setSearchTextInitial(searchText);
  }

  const onTextChange = (e: any) => {
    const text = e.target.value as string;
    setSearchTextLocal(text);
    onSearchChange?.(text);
  };

  const onSearchClick = () => {
    searchModalRef.current.open();
  };

  const SearchModalInput = forwardRef(({ onFinish }: ActionModalComponentProps, ref): any => {
    const [text, setText] = useState(searchTextLocal);

    useImperativeHandle(ref, () => ({
      submit: () => {
        setSearchTextLocal(text);
        onSearchChange?.(text);
        onFinish?.();
      },
    }));

    return (
      <InputContainer>
        <Input
          placeholder={t('common.search')}
          aria-label={t('common.search')}
          iconLeft={<StyledSearchIcon />}
          clearable
          onChange={(e: any) => setText(e.target.value as string)}
          value={text}
        />
      </InputContainer>
    );
  });

  return (
    <>
      <ShimmerSearchButtonWrapper loading={loading}>
        <StyledFilterButton
          icon={<StyledSearchIcon />}
          showBadge={searchTextLocal.length > 1}
          onClick={onSearchClick}
        />
      </ShimmerSearchButtonWrapper>
      <StyledShimmerInputWrapper loading={loading}>
        <StyledWrapper $fullWidth={fullWidth} onClick={inMobile(width) ? onSearchClick : undefined}>
          <StyledSearchInput
            id="search"
            label=""
            placeholder={searchPlaceholder}
            name="search"
            aria-label={searchPlaceholder}
            clearable
            iconLeft={<StyledSearchIcon />}
            onChange={onTextChange}
            value={searchTextLocal}
            onFocus={onFocus ? () => onFocus() : undefined}
            onBlur={onBlur ? () => onBlur() : undefined}
            $focused={focused}
            $fullWidth={fullWidth}
            $height={height}
          />
        </StyledWrapper>
      </StyledShimmerInputWrapper>
      <ActionModal
        ref={searchModalRef}
        title={searchPlaceholder}
        submitButtonTitle={t('common.search')}
        Component={SearchModalInput}
        componentProps={{
          fullWidth,
          focused,
        }}
      />
    </>
  );
};

export default SearchInput;
