import styled from 'styled-components';

import { SearchIcon } from '~/assets';
import Input from '~/components/Input';
import { ShimmerInputWrapper } from '~/components/Shimmer';
import { ThemeProp } from '~/theme/Themes';

export const InputContainer = styled.div`
  margin-top: 0.5rem;
  .nextui-input-main-container {
    margin-bottom: 0;
  }
`;

interface StyledSearchInputProps {
  $focused?: boolean;
  $fullWidth?: boolean;
  $height?: string;
}

export const StyledSearchInput = styled(Input).attrs<StyledSearchInputProps>((props) => ({
  ...props,
  marginBottom: '0',
  css: {
    [`@media (max-width: ${(props as ThemeProp).theme.breakpoints.smallMobile})`]: {
      display: 'none',
    },
    ...props.css,
  },
}))<StyledSearchInputProps>`
  $focused && {
  }

  && {
    height: ${(props) => props.$height || '2rem'};
    margin: 0;
    max-width: ${(props) => (props.$fullWidth ? '100%' : '9.5rem')};
    width: ${(props) => (props.$fullWidth ? '100%' : 'max-content')};
    border-color: ${(props) => (props.$focused ? props.theme.colors.primary.c500 : props.theme.colors.neutrals.c200)};

    @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
      max-width: ${(props) => (props.$fullWidth ? '100%' : '7.5rem')};
    }
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.black};
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.smallMobile}) {
    stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  }
`;

export const StyledShimmerInputWrapper = styled(ShimmerInputWrapper).attrs(() => {
  return { width: '9rem', height: '2rem' };
})`
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.smallMobile}) {
    display: none;
  }
`;

export const ShimmerSearchButtonWrapper = styled(ShimmerInputWrapper).attrs(() => {
  return { width: '2rem', height: '2rem', paddingText: '0.5rem' };
})`
  display: none;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.smallMobile}) {
    display: flex;
  }
`;

interface StyledWrapperProps {
  $fullWidth?: boolean;
}

export const StyledWrapper = styled.div<StyledWrapperProps>`
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.smallMobile}) {
    display: none;
  }

  ${({ $fullWidth }) => $fullWidth && `width: 100%;`}
`;
