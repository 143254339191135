import React from 'react';
import { SVGProps } from 'react';

export const SIGNAL_ICON_PATHS = {
  centerCircle:
    'M6.99967 8.16634C7.64401 8.16634 8.16634 7.64401 8.16634 6.99967C8.16634 6.35534 7.64401 5.83301 6.99967 5.83301C6.35534 5.83301 5.83301 6.35534 5.83301 6.99967C5.83301 7.64401 6.35534 8.16634 6.99967 8.16634Z',
  waves:
    'M9.47241 4.52681C9.79783 4.85187 10.056 5.23788 10.2321 5.66277C10.4083 6.08766 10.4989 6.54311 10.4989 7.00306C10.4989 7.46301 10.4083 7.91846 10.2321 8.34335C10.056 8.76824 9.79783 9.15425 9.47241 9.47931M4.52575 9.47348C4.20033 9.14842 3.94217 8.76241 3.76604 8.33752C3.5899 7.91262 3.49924 7.45718 3.49924 6.99723C3.49924 6.53727 3.5899 6.08183 3.76604 5.65694C3.94217 5.23204 4.20033 4.84603 4.52575 4.52098M11.1232 2.87598C12.2168 3.96989 12.8312 5.45335 12.8312 7.00014C12.8312 8.54693 12.2168 10.0304 11.1232 11.1243M2.87491 11.1243C1.78133 10.0304 1.16699 8.54693 1.16699 7.00014C1.16699 5.45335 1.78133 3.96989 2.87491 2.87598',
};

const SignalIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg height="14" width="14" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d={SIGNAL_ICON_PATHS.centerCircle} strokeLinecap="round" strokeLinejoin="round" />
      <path d={SIGNAL_ICON_PATHS.waves} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SignalIcon;
