import React, { createContext, FC, ReactNode, useState } from 'react';

import { useSessionContext } from '~/contexts/SessionContext';

import { usePrevious } from '../hooks/usePrevious';
import { mainSidebar, SideBarContent, SideBarSection } from '../views/layouts/SideBar';

export interface SidebarContextProps {
  sidebarContent: SideBarContent;
  sidebarContentOld: SideBarContent;
  setSidebarContent: (content: SideBarContent) => void;
}

interface SidebarProviderProps {
  children: ReactNode;
}

const SidebarContext = createContext<SidebarContextProps>({
  sidebarContent: undefined,
  sidebarContentOld: undefined,
  setSidebarContent: undefined,
});

export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
  const { orgMenuItemTypes, hasAudienceOnlyAccess, hasInviteToAnOrganization } = useSessionContext();
  const [sidebarContent, setContentLocal] = useState<SideBarContent>(
    mainSidebar(orgMenuItemTypes, hasAudienceOnlyAccess, hasInviteToAnOrganization),
  );
  const sidebarContentOld = usePrevious(sidebarContent);
  const isEqualSections = (sections1: SideBarSection[], sections2: SideBarSection[]) => {
    const stringifySections = (sectionsArray: SideBarSection[]) =>
      JSON.stringify(sectionsArray, (key, value) => (key !== 'icon' ? value : undefined));
    return stringifySections(sections1) === stringifySections(sections2);
  };

  const setContent = (contentNew: SideBarContent) => {
    if (!isEqualSections(sidebarContent?.sections, contentNew?.sections)) {
      setContentLocal(contentNew);
    }
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarContent,
        sidebarContentOld,
        setSidebarContent: setContent,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext: () => SidebarContextProps = () => {
  return React.useContext(SidebarContext);
};
