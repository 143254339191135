import { DateRange } from 'moment-range';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarIcon } from '../../assets';
import { FilterButton } from '../../overwrites/components/DateFilterButton/FilterButton.styles';
import { Modal } from '../';
import { ModalProps } from '../Modal/Modal';
import { ButtonText, StyledBadge, StyledDateRangePicker } from './DateFilterButton.styles';

interface DateFilterButtonProps {
  modalTitle?: string;
  dateRange?: DateRange;
  maximumDate?: Date;
  minDate?: Date;
  maxDaysCount?: number;
  breakpointShowButtonText?: string;
  onRangeSelect?: (range: DateRange) => void;
  onReset?: () => void;
  disabled?: boolean;
}

interface DateRangeModalProps extends Omit<ModalProps, 'children' | 'open' | 'onClose'> {
  selectedRange?: DateRange;
  onRangeSubmit: (range: DateRange) => void;
  modalTitle?: string;
  maximumDate?: Date;
  minDate?: Date;
  maxDaysCount?: number;
  onReset?: () => void;
  t: (key: string, options?: any) => string;
}

const DateRangeModal = React.memo(
  forwardRef(({ selectedRange, onRangeSubmit, ...rest }: DateRangeModalProps, ref) => {
    const [isOpen, setOpen] = useState(false);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const initialRangeRef = useRef(selectedRange);
    const [localDateRange, setLocalDateRange] = useState<DateRange | undefined>(selectedRange);

    useImperativeHandle(ref, () => ({
      open: () => {
        initialRangeRef.current = selectedRange;
        setLocalDateRange(selectedRange);
        setOpen(true);
      },
    }));

    useEffect(() => {
      if (!isOpen) {
        initialRangeRef.current = selectedRange;
        setLocalDateRange(selectedRange);
      }
    }, [isOpen, selectedRange]);

    const handleClose = React.useCallback(() => {
      setOpen(false);
      setLocalDateRange(initialRangeRef.current);
    }, []);

    const handleSubmit = React.useCallback(() => {
      if (localDateRange) {
        onRangeSubmit(localDateRange);
      }
      setOpen(false);
    }, [localDateRange, onRangeSubmit]);

    const handleDateChange = React.useCallback((newDateRange: DateRange) => {
      setLocalDateRange(newDateRange);
    }, []);

    const modalContent = useMemo(
      () => (
        <StyledDateRangePicker
          selectedRange={localDateRange}
          maxDaysCount={rest.maxDaysCount}
          minDate={rest.minDate}
          maximumDate={rest.maximumDate}
          onChange={handleDateChange}
          setIsWrongRange={setDisabledSubmitButton}
        />
      ),
      [localDateRange?.start, localDateRange?.end, rest.maxDaysCount, rest.minDate, rest.maximumDate, handleDateChange],
    );

    return (
      <Modal
        open={isOpen}
        title={rest.modalTitle || rest.t('common.filterBy')}
        width="48rem"
        submitButtonTitle={rest.t('common.apply')}
        cancelButtonTitle={rest.t('components.dateRangePicker.clearFilter')}
        onClose={handleClose}
        onCancel={rest.onReset}
        onSubmit={handleSubmit}
        disabledSubmitButton={disabledSubmitButton}
      >
        {modalContent}
      </Modal>
    );
  }),
  (prevProps, nextProps) => {
    if (!prevProps.selectedRange && !nextProps.selectedRange) return true;
    if (!prevProps.selectedRange || !nextProps.selectedRange) return false;
    return (
      prevProps.selectedRange.start.isSame(nextProps.selectedRange.start) &&
      prevProps.selectedRange.end.isSame(nextProps.selectedRange.end)
    );
  },
);

const DateFilterButton = React.memo(
  ({
    modalTitle,
    dateRange,
    maximumDate,
    minDate,
    maxDaysCount,
    breakpointShowButtonText,
    onRangeSelect,
    onReset,
    disabled,
    ...rest
  }: DateFilterButtonProps) => {
    const { t } = useTranslation();
    const dateRangeFilterRef = React.useRef(null);

    const selectedRangeText = React.useMemo(
      () =>
        dateRange
          ? `${t('dateShort', { val: dateRange.start })} - ${t('dateShort', { val: dateRange.end })}`
          : t('components.dateRangePicker.selectRange'),
      [dateRange, t],
    );

    const handleButtonClick = React.useCallback(() => {
      dateRangeFilterRef.current?.open();
    }, []);

    return (
      <>
        <FilterButton onClick={handleButtonClick} {...rest} disabled={disabled}>
          <CalendarIcon />
          <ButtonText breakpoint={breakpointShowButtonText}>{selectedRangeText}</ButtonText>
          {dateRange && <StyledBadge breakpoint={breakpointShowButtonText}>1</StyledBadge>}
        </FilterButton>
        <DateRangeModal
          ref={dateRangeFilterRef}
          selectedRange={dateRange}
          onRangeSubmit={onRangeSelect}
          modalTitle={modalTitle}
          maximumDate={maximumDate}
          minDate={minDate}
          maxDaysCount={maxDaysCount}
          onReset={onReset}
          t={t}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if these specific props change
    return (
      prevProps.dateRange?.start.isSame(nextProps.dateRange?.start) &&
      prevProps.dateRange?.end.isSame(nextProps.dateRange?.end) &&
      prevProps.disabled === nextProps.disabled &&
      prevProps.modalTitle === nextProps.modalTitle &&
      prevProps.maximumDate === nextProps.maximumDate &&
      prevProps.minDate === nextProps.minDate &&
      prevProps.maxDaysCount === nextProps.maxDaysCount &&
      prevProps.breakpointShowButtonText === nextProps.breakpointShowButtonText
    );
  },
);

export default DateFilterButton;
