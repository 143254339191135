import i18next from 'i18next';
import React from 'react';

import { AudiencesIcon, DashboardIcon, OrganizationIcon } from '~/assets';
import { homeAudiencesPath, homePath } from '~/constants';
import { AudienceCondition, OrganizationType } from '~/types/api.graphql';
import { allConditionsArray, conditionIcon, conditionTitle } from '~/utils/audience';
import { mapOrgTypeIcons } from '~/utils/organization';
import { SideBarContent } from '~/views/layouts/SideBar/SideBar';

const shouldShowSegmentsInMainList = process.env.REACT_APP_SHOW_SEGMENTS_IN_MAIN_LIST === 'true';

const getConditions = () => {
  return shouldShowSegmentsInMainList
    ? allConditionsArray
    : allConditionsArray.filter((condition) => condition !== AudienceCondition.Segment);
};

const dashboardItem = {
  items: [
    {
      title: i18next.t('dashboard.title'),
      icon: <DashboardIcon />,
      path: homePath,
    },
  ],
};

export const mainSidebar = (
  orgTypes: OrganizationType[] = [],
  hasAudienceOnlyAccess: boolean,
  hasInviteToAnOrganization: boolean,
): SideBarContent => ({
  name: 'main',
  sections: [
    ...(process.env.REACT_APP_DASHBOARD_ENABLED === 'true' ? [dashboardItem] : []),
    {
      title: i18next.t('specific:audiences.title'),
      collapsedProps: {
        title: i18next.t('audiences.titleShort'),
      },
      items: [
        {
          title: i18next.t('specific:audiences.allAudiencesTitle'),
          icon: <AudiencesIcon />,
          path: homeAudiencesPath,
          defaultSelected: true,
          navigateOptions: { state: { audienceConditionFilterId: undefined } },
        },
        ...getConditions()
          .filter((condition) =>
            condition === AudienceCondition.Lookalike ? process.env.REACT_APP_LOOKALIKE_ENABLED === 'true' : true,
          )
          .map((condition) => ({
            title: conditionTitle(condition),
            icon: conditionIcon(condition),
            path: homeAudiencesPath,
            navigateOptions: { state: { audienceConditionFilterId: condition } },
            badge: condition === AudienceCondition.Lookalike ? i18next.t('newLookalikeAudience.ai') : undefined,
          })),
      ],
    },
    /* eslint-disable @typescript-eslint/indent */
    ...(!hasAudienceOnlyAccess || hasInviteToAnOrganization
      ? [
          {
            title: i18next.t('organizations.title'),
            collapsedProps: {
              title: i18next.t('organizations.titleShort'),
            },
            items: [
              {
                title: i18next.t('organizations.allOrganizationsTitle'),
                icon: <OrganizationIcon />,
                path: '/app/organizations',
                defaultSelected: true,
                navigateOptions: { state: { organizationType: undefined } },
              },
              ...orgTypes.map((organizationType) => ({
                title: i18next.t(`organization.settings.orgTypes.${organizationType.toLowerCase()}`),
                icon: mapOrgTypeIcons[organizationType],
                path: '/app/organizations',
                navigateOptions: { state: { organizationType } },
              })),
            ],
          },
        ]
      : []),
    /* eslint-enable @typescript-eslint/indent */
  ],
});
