
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AudienceInfo": [
      "AudienceCustomerListInfo",
      "AudienceGlobalInfo",
      "AudienceLookalikeInfo",
      "AudiencePixelInfo",
      "AudiencePulseInfo"
    ],
    "AudienceIntegrationConfiguration": [
      "ADFAudienceIntegrationConfiguration",
      "FacebookCustomAudienceAudienceIntegrationConfiguration",
      "GoogleCustomerMatchAudienceIntegrationConfiguration",
      "S3AudienceIntegrationConfiguration",
      "ZapierAudienceIntegrationConfiguration"
    ],
    "BaseAdditionsArrayInfo": [
      "GlobalAdditionsArrayInfo",
      "PulseAdditionsArrayInfo"
    ],
    "BaseAdditionsInfo": [
      "AudienceGlobalInfo",
      "AudiencePulseInfo"
    ],
    "BaseAudience": [
      "Audience",
      "RestrictedAccessAudience"
    ],
    "BaseOrganization": [
      "Organization",
      "RestrictedAccessOrganization"
    ],
    "BaseProcessingAdditionInfo": [
      "GlobalProcessingAdditionInfo",
      "PulseProcessingAdditionInfo"
    ],
    "BaseUser": [
      "RestrictedAccessUser",
      "User"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AudienceInfo": [
      "AudienceCustomerListInfo",
      "AudienceGlobalInfo",
      "AudienceLookalikeInfo",
      "AudiencePixelInfo",
      "AudiencePulseInfo"
    ],
    "AudienceIntegrationConfiguration": [
      "ADFAudienceIntegrationConfiguration",
      "FacebookCustomAudienceAudienceIntegrationConfiguration",
      "GoogleCustomerMatchAudienceIntegrationConfiguration",
      "S3AudienceIntegrationConfiguration",
      "ZapierAudienceIntegrationConfiguration"
    ],
    "BaseAdditionsArrayInfo": [
      "GlobalAdditionsArrayInfo",
      "PulseAdditionsArrayInfo"
    ],
    "BaseAdditionsInfo": [
      "AudienceGlobalInfo",
      "AudiencePulseInfo"
    ],
    "BaseAudience": [
      "Audience",
      "RestrictedAccessAudience"
    ],
    "BaseOrganization": [
      "Organization",
      "RestrictedAccessOrganization"
    ],
    "BaseProcessingAdditionInfo": [
      "GlobalProcessingAdditionInfo",
      "PulseProcessingAdditionInfo"
    ],
    "BaseUser": [
      "RestrictedAccessUser",
      "User"
    ]
  }
};
      export default result;
    